import React, { useContext, useState, useEffect } from "react";
import { Text, Box, IconButton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { LockIcon, UnlockIcon } from "@chakra-ui/icons";
import { AccountContext } from "./Accounts";
import { userHasAccess } from "../entry";

export default () => {
  const [status, setStatus] = useState(false);
  const [userName, setUserName] = useState("");

  const navigate = useNavigate();
  const { logout, email } = useContext(AccountContext);

  useEffect(() => {
    async function run() {
      const result = await userHasAccess();
      setUserName(await email());
      setStatus(result);
    }
    run();
  }, []);

  const onSignIn = () => {
    navigate("/avatar-arena");
  };

  const onSignOut = () => {
    logout();
    navigate("/login");
  };

  return (
    <Box>
      {status ? (
        <Box>
          <Text display={"inline-block"} m="0 15px">
            Welcome {userName}
          </Text>

          <IconButton
            color="gray.600"
            borderColor="gray.600"
            variant="outline"
            aria-label="Sign Out"
            icon={<LockIcon />}
            onClick={onSignOut}
          />
        </Box>
      ) : (
        <Box>
          <IconButton
            color="gray.600"
            borderColor="gray.600"
            variant="outline"
            aria-label="Sign In"
            icon={<UnlockIcon />}
            onClick={onSignIn}
          />
        </Box>
      )}
    </Box>
  );
};
