import React, { useState } from "react";
import { Button, Box, Input, Heading, Text } from "@chakra-ui/react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import Pool from "../components/UserPool";
import Layout from "../components/Layout";
import ErrorMsg from "../components/ErrorMsg";

export default () => {
  const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const getUser = () => {
    return new CognitoUser({
      Username: email.toLocaleLowerCase(),
      Pool,
    });
  };

  const sendCode = (event) => {
    event.preventDefault();

    getUser().forgotPassword({
      onSuccess: (data) => {},
      onFailure: (err) => {
        const errorMessage = err.message;
        setErrorMsg(errorMessage);
      },
      inputVerificationCode: (data) => {
        setStage(2);
      },
    });
  };

  const resetPassword = (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setErrorMsg("Passwords do not match");
    }

    getUser().confirmPassword(code, password, {
      onSuccess: (data) => {
        setErrorMsg("");
        navigate("/login");
      },
      onFailure: (err) => {
        const errorMessage = err.message;
        setErrorMsg(errorMessage);
      },
    });
  };

  return (
    <Box>
      <Heading>Reset Your Password</Heading>
      {stage === 1 && (
        <form onSubmit={sendCode}>
          <Text m="15px 0">
            Enter the email address associated with this account and we will
            send you a verification code.
          </Text>

          <label htmlFor="email">Email</label>
          <Input
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          <Button type="submit">Send verification code</Button>
          {errorMsg !== "" && <ErrorMsg msg={errorMsg}></ErrorMsg>}
        </form>
      )}

      {stage === 2 && (
        <Box>
          <form onSubmit={resetPassword}>
            <label htmlFor="code">Code</label>
            <Input
              value={code}
              onChange={(event) => setCode(event.target.value)}
            />

            <label htmlFor="password">New Password</label>
            <Input
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />

            <label htmlFor="confirmPassword">Confirm Password</label>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
            />

            <Button type="submit">Change Password</Button>
          </form>

          <Box m="15px">
            <p>Password Policy</p>
            <ul>
              <li>Password minimum length: 8 character(s)</li>
              <li>
                Temporary passwords set by administrators expire in 7 days
              </li>
              <li>Password must contain at least 1 number</li>
              <li>Password must contain at least 1 special character</li>
              <li>Password must contain at least 1 uppercase letter</li>
              <li>Password must contain at least 1 lowercase letter</li>
            </ul>
          </Box>
          {errorMsg !== "" && <ErrorMsg msg={errorMsg}></ErrorMsg>}
        </Box>
      )}
    </Box>
  );
};
