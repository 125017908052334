import React from "react";
import { defineStyleConfig } from "@chakra-ui/react";

const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    textTransform: "uppercase",
    borderRadius: "base",
    margin: "10px 0",
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: "2px solid",
      borderColor: "blue.500",
      color: "blue.500",
    },
    solid: {
      bg: "gray.700",
      color: "#02F1FE",
      _hover: {
        color: "gray.700",
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "solid",
  },
});

const Heading = defineStyleConfig({
  baseStyle: {
    margin: "10px 0",
    color: "gray.700",
  },
});

const Input = {
  baseStyle: {
    field: {
      margin: "0 0 15px 0",
      width: "400px",
      display: "block",
      _hover: {
        borderColor: "primary.foreground",
      },
    },
  },
  sizes: {},
  defaultProps: {},
};

export { Button, Heading, Input };
