import React, { createContext, useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./UserPool";
import { getSession } from "../entry";

const AccountContext = createContext();

const Account = (props) => {
  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
      });

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve({ data, user: Pool.getCurrentUser() });
        },
        onFailure: (err) => {
          console.error("onFailure: ", err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          resolve({ data, user: Pool.getCurrentUser() });
        },
      });
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };

  const user = Pool.getCurrentUser();

  const email = async () => {
    const session = await getSession();
    return session.email;
  };

  const changePassword = async (password, newPassword) => {
    const username = await email();
    const authenticated = await authenticate(username, password);
    const session = await getSession();

    session.user.changePassword(password, newPassword, (err, result) => {
      if (err) {
        console.error(err);
      }
    });
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        user,
        email,
        logout,
        changePassword,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
