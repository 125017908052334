import React from "react";
import { Button, Box, Input, Heading, Link } from "@chakra-ui/react";
import ChangePassword from "./ChangePassword";

export default () => {
  return (
    <Box>
      {
        <Box>
          <Heading>User Settings</Heading>
          <ChangePassword />
        </Box>
      }
    </Box>
  );
};
