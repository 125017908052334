import React from "react";
import ForgotPassword from "../components/ForgotPassword";
import Layout from "../components/Layout";

function ResetPassword() {
  return (
    <Layout>
      <ForgotPassword />
    </Layout>
  );
}

export default ResetPassword;
