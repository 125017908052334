import React, { useEffect } from "react";
import { signOut, userAuthRoutes } from "../entry";

function Logout() {
  useEffect(() => {
    const logout = async () => {
      try {
        const userLogout = await signOut();
        window.location.href = userAuthRoutes.afterLogoutPath;
      } catch (err) {
        console.error(err);
      }
    };
    logout();
  }, []);

  return <div>Logging out!</div>;
}

export default Logout;
