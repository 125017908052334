import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  ChakraProvider,
  defineStyleConfig,
  extendTheme,
} from "@chakra-ui/react";
import Login from "./pages/Login";
import { Account } from "./components/Accounts";
import Status from "./components/Status";
// import ForgotPassword from './components/ForgotPassword';
import ResetPassword from "./pages/ResetPassword";
import Settings from "./pages/Settings";
import Header from "./components/Header";
import { Button, Heading, Input } from "./components/CustomComponents";
import Logout from "./pages/Logout";

function App() {
  const theme = extendTheme({
    components: {
      Button,
      Heading,
      Input,
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Account>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/" element={<Layout />}> */}
            <Route path="/user/login" element={<Login />} />
            <Route path="/user/logout" element={<Logout />} />
            <Route path="/user/reset-password" element={<ResetPassword />} />
            <Route path="/user/settings" element={<Settings />} />
            {/* </Route> */}
          </Routes>
        </BrowserRouter>
      </Account>
    </ChakraProvider>
  );
}

export default App;
