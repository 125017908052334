import React, { useState, useContext } from "react";
import { Link as ReachLink } from "@reach/router";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Box, Input, Heading, Link } from "@chakra-ui/react";
import Layout from "../components/Layout";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../components/UserPool";
import ErrorMsg from "../components/ErrorMsg";
import { userAuthRoutes } from "../entry";

function Login() {
  const [password, setPassword] = useState("");
  const [newUser, setNewUser] = useState(false);
  const [challengePassword, setChallengePassword] = useState("");
  const [authUser, setAuthUser] = useState();
  const [userAttr, setUserAttr] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const existUserName = queryParams.get("username");
  const [email, setEmail] = useState(existUserName || "");

  const navigate = useNavigate();

  const onSubmit = (event) => {
    event.preventDefault();

    authenticateUser(email, password)
      .then((data) => {
        if (data) {
          window.location.href = userAuthRoutes.afterAuthPath;
        }
      })
      .catch((err) => {
        console.error("Failed to log in: ", err);
      })
      .finally((data) => {});
  };

  const onSubmitChallenge = (event) => {
    event.preventDefault();

    completePasswordChallenge(challengePassword, authUser)
      .then((data) => {})
      .catch((err) => {
        console.error("Failed to log in: ", err);
      })
      .finally((data) => {});
  };

  const completePasswordChallenge = async (challengePassword, authUser) => {
    await new Promise((resolve, reject) => {
      authUser.completeNewPasswordChallenge(challengePassword, userAttr, {
        onSuccess: (result) => {
          // login
          setNewUser(false);
          navigate("/user/login");
        },
        onFailure: (result) => {
          // login
        },
      });
    });
  };

  const authenticateUser = async (Username, Password) => {
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
      });

      setAuthUser(user);

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          window.location.href = userAuthRoutes.afterAuthPath;
          resolve(data);
        },
        onFailure: (err) => {
          console.error("onFailure: ", err);

          const errorMessage = err.message;
          setErrorMsg(errorMessage);
          resolve(err);
        },
        newPasswordRequired: (userAttr) => {
          // in this case, we will need to call Update Password function
          setNewUser(true);
          setErrorMsg("");

          delete userAttr.email_verified;
          setUserAttr(userAttr);
          resolve(userAttr);
        },
      });
    });
  };

  return (
    <Layout>
      {newUser ? (
        <Box>
          <Heading>Update Password</Heading>
          <form onSubmit={onSubmitChallenge}>
            <label htmlFor="email">New Password</label>
            <Input
              type="password"
              value={challengePassword}
              onChange={(event) => setChallengePassword(event.target.value)}
            ></Input>

            <Button type="submit">Update Password</Button>
          </form>
        </Box>
      ) : (
        <Box>
          <Heading>Sign In</Heading>
          <form onSubmit={onSubmit}>
            <label htmlFor="email">Email</label>
            <Input
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            ></Input>

            <label htmlFor="password">Password</label>
            <Input
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              type="password"
            ></Input>

            <Button type="submit">Log in</Button>
          </form>

          <Link as={ReachLink} to={userAuthRoutes.resetPassword}>
            Forgot Password
          </Link>
        </Box>
      )}

      {errorMsg !== "" && <ErrorMsg msg={errorMsg}></ErrorMsg>}
    </Layout>
  );
}

export default Login;
