import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import SettingsComponent from "../components/Settings";
import { userHasAccess } from "../entry";

function Settings() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(["itu-admin"]);
      setLoggedIn(result);
      console.log("result", result);
    }
    run();
  }, []);

  return <Layout>{loggedIn && <SettingsComponent />}</Layout>;
}

export default Settings;
