import React from "react";
import { Box, Text } from "@chakra-ui/react";

export default (props) => {
  return (
    <Box bgColor="red.100" minH="100px" m="10px 0" p="15px">
      <Text>{props.msg}</Text>
    </Box>
  );
};
