import lifecycles from "./impact-theory-app-auth.js";
import Pool from "./components/UserPool";

const accessGroup = "SuperAdmin";

export const userAuthRoutes = {
  login: "/user/login",
  logout: "/user/logout",
  settings: "/user/settings",
  resetPassword: "/user/reset-password",
  // These are the routes that the user will be redirected to after
  // login, logout, registration, etc.
  afterAuthPath: "/avatar-arena/tournaments", // The default
  afterLogoutPath: "/", // The default
};

export async function signOut() {
  try {
    const session = await getSession();
    return await session.user.signOut();
  } catch (err) {
    console.error(err.message);
    return "";
  }
}

export async function getUsersEmail() {
  try {
    const session = await getSession();
    return session.email;
  } catch (err) {
    console.error(err.message);
    return "";
  }
}

export async function getUsersJWT() {
  try {
    const session = await getSession();
    const jwt = session.accessToken.jwtToken;
    return jwt;
  } catch (err) {
    console.error(err.message);
    return "";
  }
}

export async function userHasAccess(optionalGroups = []) {
  const session = await getSession();
  if (!session) {
    console.log("No session");
    return false;
  }
  const groups = session.accessToken.payload["cognito:groups"];
  const superAdmin = groups.includes(accessGroup);
  return superAdmin || optionalGroups.some((group) => groups.includes(group));
}

export const getSession = async () =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();

    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          reject();
        } else {
          const attributes = await new Promise((resolve, reject) => {
            user.getUserAttributes((err, attributes) => {
              if (err) {
                reject(err);
              } else {
                const results = {};

                for (let attribute of attributes) {
                  const { Name, Value } = attribute;
                  results[Name] = Value;
                }

                resolve(results);
              }
            });
          });

          resolve({
            user,
            ...session,
            ...attributes,
          });
        }
      });
    } else {
      resolve(false);
    }
  });

export const { bootstrap, mount, unmount } = lifecycles;
