import React, { useState, useContext } from "react";
import { Box, Input, Heading, Button } from "@chakra-ui/react";
import { AccountContext } from "./Accounts";
import ErrorMsg from "./ErrorMsg";

export default () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const { changePassword } = useContext(AccountContext);

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await changePassword(password, newPassword);
    } catch (error) {
      setErrorMsg(error.message);
    }
  };

  return (
    <Box m="25px 0">
      <Heading as="h4" size="md" m="10px 0">
        Change Password
      </Heading>

      <form onSubmit={onSubmit}>
        <label htmlFor="password">Old Password</label>
        <Input
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />

        <label htmlFor="newPassword">New Password</label>
        <Input
          type="password"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />

        <Button type="submit">Change Password</Button>

        {errorMsg && <ErrorMsg msg={errorMsg}></ErrorMsg>}
      </form>
    </Box>
  );
};
